import React, { Component } from 'react';

class ToolHome extends Component {
  render(){
    return (
        <div>Tools page</div>
    );
  }  
};
 
export default ToolHome;